// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-courses-js": () => import("./../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */),
  "component---src-templates-course-js": () => import("./../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-episode-free-js": () => import("./../src/templates/episode-free.js" /* webpackChunkName: "component---src-templates-episode-free-js" */),
  "component---src-templates-episode-paid-js": () => import("./../src/templates/episode-paid.js" /* webpackChunkName: "component---src-templates-episode-paid-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cancelled-js": () => import("./../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-password-reset-js": () => import("./../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchase-failed-js": () => import("./../src/pages/purchase-failed.js" /* webpackChunkName: "component---src-pages-purchase-failed-js" */),
  "component---src-pages-purchase-success-js": () => import("./../src/pages/purchase-success.js" /* webpackChunkName: "component---src-pages-purchase-success-js" */),
  "component---src-pages-refunds-js": () => import("./../src/pages/refunds.js" /* webpackChunkName: "component---src-pages-refunds-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

