import './src/global.css'
import 'typeface-inter'

import React from 'react'
import { useAuth, UserContext } from './src/util/context.js'

const Auth = props => {
    const authData = useAuth()
    return <UserContext.Provider value={authData}>{props.children}</UserContext.Provider>
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return <Auth {...props}>{element}</Auth>
}
