import React from 'react'
import { FirebaseContext } from 'gatsby-plugin-firebase'

export const UserContext = React.createContext({
    user: null,
    isLoading: true,
})

export const useSession = () => {
    return React.useContext(UserContext)
}

export const useAuth = () => {
    const fb = React.useContext(FirebaseContext)

    const [state, setState] = React.useState(() => {
        const user = fb && fb.auth().currentUser
        return { isLoading: !user, user }
    })

    const onChange = user => setState({ isLoading: false, user })
    React.useEffect(() => {
        if (fb) {
            return fb.auth().onAuthStateChanged(onChange)
        }
    }, [fb])

    return state
}
