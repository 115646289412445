module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://d088225d366c458cb82744017f828d81@sentry.io/4016824"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Indie Nuggets","short_name":"Indie Nuggets","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121178439-3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"firestore":true},"credentials":{"apiKey":"AIzaSyDPv7KIG7SWslN9C6-WFHiiF_24LJ0y9h0","authDomain":"auth.indienuggets.com","databaseURL":"https://indienuggets-cfc29.firebaseio.com","projectId":"indienuggets-cfc29","storageBucket":"indienuggets-cfc29.appspot.com","messagingSenderId":"436858462796","appId":"1:436858462796:web:0024e5be74aee88e3f8ae9"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
